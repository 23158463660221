<template>
  <div id="mapContainer" class="basemap"></div>
</template>

<script>

import mapboxgl from "mapbox-gl";

export default {
props: { source: String,},
name: "BaseMap",
data() {
  return {
    accessToken: 'pk.eyJ1IjoicHJvbXlzaGx5YW5za2l5IiwiYSI6ImNrbXB1emd4NDJpYjQyb3Q0ejlzOGF0NmEifQ.xZozgwAxTFYSAaFip6jsfQ',
  };
},
mounted() {
  mapboxgl.accessToken = this.accessToken;

  new mapboxgl.Map({
    container: "mapContainer",
    style: "mapbox://styles/mapbox/dark-v10",
    center: [8.590, 47.278],
    zoom: 4.5,
  });
},
};
</script>

<style lang="scss">
.basemap {
width: 100%;
height: 100%;
}
</style>
